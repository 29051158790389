import { Component, inject } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { map } from "rxjs";
import { Constants } from "src/app/constants/constants";
import { CustomImage } from "src/app/models/shared";
import { TitleService } from "src/app/services/title.service";
import { CustomImageService } from "../custom-image.service";

@Component({
    selector: "app-custom-image-form",
    templateUrl: "./custom-image-form.component.html"
})
export class CustomImageFormComponent {
    private fb = inject(FormBuilder);
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private customImageService = inject(CustomImageService);
    private titleService = inject(TitleService);
    customImageNames$ = this.customImageService.customImages.pipe(
        map(customImages => {
            if (this.customImage)
                customImages = customImages.filter(customImage => customImage.id !== this.customImage.id);
            return customImages.map(customImage => customImage.name);
        })
    );

    nvidiaCudaVersionTypes = {
        "10": "10",
        "11": "11",
        "12": "12"
    };
    architectureTypes = { "64-bit (x86)": "x86", "64-bit (Arm)": "arm" };
    loading = false;
    saving = false;
    submitted = false;
    isEdit = false;
    isClone = false;
    constants = Constants;
    customImage: CustomImage;
    form = this.fb.group({
        name: ["", [Validators.required, Validators.minLength(2), Validators.pattern(Constants.validators.name)]],
        ami_id: ["", [Validators.required, Validators.pattern("^ami-[0-9a-zA-Z]+$")]], // ami-XXX
        region: [null as string | null, [Validators.required]],
        nvidia_cuda_version: ["", [Validators.required]], //"10" | "11" | "12";
        architecture: ["", [Validators.required]] //"x86" | "arm";
    });

    constructor() {
        this.route.paramMap.subscribe(params => {
            const action = params.get("action");
            const customImageId = params.get("id");
            if (action && customImageId) {
                if (action === "edit") this.isEdit = true;
                if (action === "clone") this.isClone = true;
                this.customImage = this.customImageService.getCachedCustomImage(Number(customImageId));
                if (!this.customImage) this.router.navigate([Constants.urls.configuration.customImage]);
                this.prepForm();

                // Set Title
                this.titleService.setTitle("AWS_AMI", action, this.customImage);
            }
        });
    }

    private prepForm() {
        if (this.customImage)
            this.form.patchValue({
                name: this.isEdit ? this.customImage.name : "",
                ami_id: this.isEdit ? this.customImage.ami_id : "",
                architecture: this.customImage.architecture,
                region: this.customImage.region,
                nvidia_cuda_version: this.customImage.nvidia_cuda_version
            });
    }

    async onSubmit() {
        this.saving = true;
        const model = this.form.value as CustomImage;
        const response = this.isEdit
            ? await this.customImageService.updateCustomImage(this.customImage.id, model)
            : await this.customImageService.addCustomImage(model);
        this.saving = false;
        if (response) this.router.navigate([Constants.urls.configuration.customImage]);
    }
}
